<template>
  <div class="customizer">
    <div class="handle" @click="getYear();getTeacher();"><i class="i-Gear "></i></div>
    <div class="customizer-body">
      <div class="accordion" id="accordionCustomizer">
        <div v-show="changeColor" class="changeColor text-center">
          <div class="card-header" id="headingOne">
            <p class="mb-0">Sidebar Colors</p>
          </div>
          <div class="collapse show" id="collapseOne" aria-labelledby="headingOne" data-parent="#accordionCustomizer">
            <div class="card-body">
              <div  class="colors sidebar-colors">

                <a @click="changeThemeColor('gradient-purple-indigo',$event)" class="color gradient-purple-indigo active" data-sidebar-class="sidebar-gradient-purple-indigo" data-theme-color="gradient-purple-indigo"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('gradient-black-blue',$event)" class="color gradient-black-blue" data-sidebar-class="sidebar-gradient-black-blue" data-theme-color="gradient-black-blue"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('gradient-black-gray',$event)" class="color gradient-black-gray" data-sidebar-class="sidebar-gradient-black-gray" data-theme-color="gradient-black-gray"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('gradient-ibiza-sunset',$event)" class="color gradient-ibiza-sunset" data-sidebar-class="sidebar-gradient-ibiza-sunset" data-theme-color="gradient-ibiza-sunset"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('dark-purple',$event)" class="color dark-purple" data-sidebar-class="sidebar-dark-purple" data-theme-color="dark-purple"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('slate-gray',$event)" class="color slate-gray" data-sidebar-class="sidebar-slate-gray" data-theme-color="slate-gray"><i class="i-Eye"></i></a>

                <a  @click="changeThemeColor('gradient-color1',$event)" class="color gradient-color1" data-sidebar-class="sidebar-gradient-color1" data-theme-color="gradient-color1"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('gradient-color2',$event)" class="color gradient-color2" data-sidebar-class="sidebar-gradient-color2" data-theme-color="gradient-color2"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('gradient-color3',$event)" class="color gradient-color3" data-sidebar-class="sidebar-gradient-color3" data-theme-color="gradient-color3"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('gradient-color4',$event)" class="color gradient-color4" data-sidebar-class="sidebar-gradient-color4" data-theme-color="gradient-color4"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('gradient-color5',$event)" class="color gradient-color5" data-sidebar-class="sidebar-gradient-color5" data-theme-color="gradient-color5"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('gradient-color6',$event)" class="color gradient-color6" data-sidebar-class="sidebar-gradient-color6" data-theme-color="gradient-color6"><i class="i-Eye"></i></a>
                
                <a  @click="changeThemeColor('gradient-color7',$event)" class="color gradient-color7" data-sidebar-class="sidebar-gradient-color7" data-theme-color="gradient-color7"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('gradient-color8',$event)" class="color gradient-color8" data-sidebar-class="sidebar-gradient-color8" data-theme-color="gradient-color8"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('gradient-color9',$event)" class="color gradient-color9" data-sidebar-class="sidebar-gradient-color9" data-theme-color="gradient-color9"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('gradient-color10',$event)" class="color gradient-color10" data-sidebar-class="sidebar-gradient-color10" data-theme-color="gradient-color10"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('gradient-color11',$event)" class="color gradient-color11" data-sidebar-class="sidebar-gradient-color11" data-theme-color="gradient-color11"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('gradient-color12',$event)" class="color gradient-color12" data-sidebar-class="sidebar-gradient-color12" data-theme-color="gradient-color12"><i class="i-Eye"></i></a>
                
                <div class="border-top w-100 my-2"></div>

                <a  @click="changeThemeColor('midnight-blue',$event)" class="color midnight-blue" data-sidebar-class="sidebar-midnight-blue" data-theme-color="midnight-blue"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('blue',$event)" class="color blue" data-sidebar-class="sidebar-blue" data-theme-color="blue"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('indigo',$event)" class="color indigo" data-sidebar-class="sidebar-indigo" data-theme-color="indigo"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('pink',$event)" class="color pink" data-sidebar-class="sidebar-pink" data-theme-color="pink"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('red',$event)" class="color red" data-sidebar-class="sidebar-red" data-theme-color="red"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('purple',$event)" class="color purple" data-sidebar-class="sidebar-purple" data-theme-color="purple"><i class="i-Eye"></i></a>

                <a @click="changeThemeColor('orange',$event)" class="color orange" data-sidebar-class="sidebar-orange" data-theme-color="orange"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('yellow',$event)" class="color yellow" data-sidebar-class="sidebar-yellow" data-theme-color="yellow"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('green',$event)" class="color green" data-sidebar-class="sidebar-green" data-theme-color="green"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('teal',$event)" class="color teal" data-sidebar-class="sidebar-teal" data-theme-color="teal"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('cyan',$event)" class="color cyan" data-sidebar-class="sidebar-cyan" data-theme-color="cyan"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('gray',$event)" class="color gray" data-sidebar-class="sidebar-gray" data-theme-color="gray"><i class="i-Eye"></i></a>
              
                <a @click="changeThemeColor('color7',$event)" class="color color7" data-sidebar-class="sidebar-color7" data-theme-color="color7"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('color8',$event)" class="color color8" data-sidebar-class="sidebar-color8" data-theme-color="color8"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('color9',$event)" class="color color9" data-sidebar-class="sidebar-color9" data-theme-color="color9"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('color10',$event)" class="color color10" data-sidebar-class="sidebar-color10" data-theme-color="color10"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('color11',$event)" class="color color11" data-sidebar-class="sidebar-color11" data-theme-color="color11"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('color12',$event)" class="color color12" data-sidebar-class="sidebar-color12" data-theme-color="color12"><i class="i-Eye"></i></a>
              
              </div>
            </div>
          </div>
        </div>

        <div class="text-center">

          <div id="collapseTwo2" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordionCustomizer">
            <div class="px-2">
              <label class="checkbox checkbox-primary">
                <input type="checkbox" @change="fromtoDarkMode" id="dark-checkbox">
                <span>Enable Dark Mode</span>
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div v-if="branchFlag && $role != 'student'" id="collapseTwo2f_" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordionCustomizer">
            <span>{{$t('selectBranch.text')}}  </span>
            <div class="card-body">
              <Multiselect  v-model="userActiveBranch"
                            :options="branches"
                            mode="single" :closeOnSelect="true"
                            :hideSelected="false"
                            :createTag="true"
                            :searchable="true"
                            @select="chgBranch()"
                            valueProp="id" label="branchName" track-by="branchName" />
            </div>
          </div>
          <div  id="collapseTwo2f_" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordionCustomizer">
            <span>{{$t('selectAcdemic.text')}}  </span>
            <div class="card-body">
              <Multiselect  v-model="selectedYear"
                            :options="filterAcadmicYear"
                            mode="single" :closeOnSelect="true"
                            :hideSelected="false"
                            :createTag="true"
                            :searchable="true"
                            :loading="selectLoading"
                            @select="chgYear()"
                            valueProp="id" label="yearTitle" track-by="yearTitle" />
            </div>
          </div>
          <div v-if="$role =='ministerial'" id="collapseTwo2f" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordionCustomizer">
            <span>{{$t('selectTeacher.text')}} </span>
            <div class="card-body">
              <Multiselect  v-model="selectedTeacher"
                            :options="teachers"
                            mode="single" :closeOnSelect="false"
                            :hideSelected="false"
                            :createTag="true"
                            :searchable="true"
                            @select="chgTeacher()"
                            valueProp="id" label="fullName" track-by="fullName" />
            </div>
          </div>



          <div v-if="accountingFlag" id="collapseTwo2f_" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordionCustomizer">
            <span>{{$t('selectFinancialYear.text')}}  </span>
            <div class="card-body">
              <Multiselect  v-model="selectedFinancialYear"
                            :options="filterFinancialYears"
                            mode="single" :closeOnSelect="true"
                            :hideSelected="false"
                            :createTag="true"
                            :searchable="true"
                            :loading="selectLoading"
                            @select="chgFinancialYear()"
                            valueProp="identifier" label="title" track-by="title" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import Multiselect from "@vueform/multiselect";

export default {  components: {
    Multiselect
  },
  name: "customizer",
  data() {
    return {
      branches:"",
      teachers :"",
      years :[],
      financialYears:[],
      selectedTeacher:"",
      selectedYear:"",
      userActiveBranch:"",
      publicPath: process.env.BASE_URL,
      branchFlag:0,
      accountingFlag:0,
      selectedFinancialYear:"",
      selectLoading:false

    }
  },
  created() {

  },

  methods: {
    fromtoDarkMode(e) {
        if (!document.body.classList.contains('dark-theme')) {
            if (e.target.checked) {
                document.querySelector('.customizer .customizer-body .colors .color.active').classList.remove('active');
                document.querySelector('.customizer .customizer-body .colors .color.midnight-blue').classList.add('active');
                document.querySelector('.customizer .customizer-body .colors .color.midnight-blue').click();
                const formData = {
                    spec: "defTheme",
                    value: 'midnight-blue'
                }
                this.$axios.post('accountSettings/profile', formData).then(response => {
                    if (response.data.status === "success") {
                        this.$toast.success(response.data.message);
                        localStorage.setItem('themeColor', 'midnight-blue')
                    } else {
                        this.$toast.error(response.data.message);
                    }
                });
            }
        }
    },
    getTeacher() {
      if(this.$role == 'ministerial') {

        this.$axios.get('dashboard/getTeacher?s=' + localStorage.getItem('schoolName')).then(response => {
          this.teachers = response.data.teachers;
          this.selectedTeacher = response.data.getTeacher;
        });
      }
    },
    getYear() {
        this.$axios.get('dashboard/getYear?s=' + localStorage.getItem('schoolName')).then(response => {
          this.years = response.data.years;
          this.selectedYear = response.data.selectAcYear;
          this.branches = response.data.userBranches;
          this.userActiveBranch = response.data.userActiveBranch;
          this.branchFlag = response.data.branchFlag;
          this.accountingFlag = response.data.accountingFlag;
          this.financialYears = response.data.FinancialYears;

          this.selectedFinancialYear = response.data.selectFinancialYear;
          console.log(this.selectedFinancialYear)
        });
    },
    getBranchTeachers(check){
      if(!check) {
        this.form.teachers =[];
      }
      this.selectLoading = true;
      this.$axios.get('getTeachers/branches',{params : { 'branches' : this.form.branches}})
          .then(response => {
            this.teachers = response.data.teachers;
            this.selectLoading = false;
          });
    },
    chgTeacher() {
      this.$axios.post('dashboard/changeTeacher',
          {'teacher': this.selectedTeacher}
      ).then(response => {
        console.log(response);
        this.loadDashboard();
      });
    },
    chgYear() {
      this.$axios.post('dashboard/changeAcYear',
          {'year': this.selectedYear,'branch': this.userActiveBranch}
      ).then(response => {
        console.log(response);
        localStorage.setItem('selectAcadmicYear',this.selectedYear );
        this.loadDashboard();
      });
    },
    chgBranch(){
      this.selectLoading = true;
      this.selectedYear=null;
      this.financialYears = null;
      this.years = [] ;
      this.$axios.post('dashboard/changeBranch',
          {'branch': this.userActiveBranch}
      ).then(response => {
        if(response.data.branchAcademicYear.length>0){
          this.years = response.data.branchAcademicYear;
          this.selectedYear=response.data.currentActiveAcademicYear;
          this.chgYear();
          this.getYear();
          this.selectLoading =0;
        }else{
          console.log(response.data.branchAcademicYear.length);
          this.years=null;
          this.selectedYear=null;
        }
        if(this.$role == 'ministerial') {
          this.getBranchTeachers();
        }
        localStorage.setItem('userActiveBranch',this.userActiveBranch );
        this.loadDashboard();
      });
    },
    chgFinancialYear(){
       this.$axios.post('dashboard/userFinancialYear',
          {'userFinancialYear': this.selectedFinancialYear,'branch': this.userActiveBranch}
      ).then(response => {
        console.log(response);
        localStorage.setItem('selectedFinancialYear',this.selectedFinancialYear );
        this.loadDashboard();
      });
    },
    changeThemeColor(color,event) {
      const formData = {
        spec: "defTheme",
        value: color
      }
      if(event.isTrusted) {
        this.$axios.post('accountSettings/profile', formData).then(response => {
          if (response.data.status === "success") {
            this.$toast.success(response.data.message);
            localStorage.setItem('themeColor', color)
          } else {
            this.$toast.error(response.data.message);
          }
        });
      }

    },
    ...mapActions({
      loadDashboard: 'dashboard/loadDashboard',
    })
  },
  computed: {
    changeColor() {
      return this.$store.state.dashboardData.layoutColorUserChange  == 1;
    },
    ...mapState('dashboard', {
      dashboardData : 'dashboardData',
    }),
    filterAcadmicYear() {
      return this.years.map(year => ({
        id: year.id,
        yearTitle: year.isDefault == '1' ? year.yearTitle + ' - ' + this.$t('defAcademicYears.text') : year.yearTitle,
      }))
    },
    filterFinancialYears() {
      return this.financialYears.map(year => ({
        identifier: year.identifier,
        title: year.is_default == '1' ? year.title + ' - ' + this.$t('defFinancialYear.text') : year.title,
      }))
    },
  },
  watch: {

  },



}
</script>

<style scoped>

</style>